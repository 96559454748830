    @tailwind base;
    @tailwind components;
    @tailwind utilities;

    @layer components {
        .btn {
            @apply h-14 rounded-lg dark:bg-[#0c4469];
        }

        .form-input {
            @apply h-14 pl-10 rounded-md dark:bg-gray-900;
        }
    }

    * {
        scrollbar-width: thin;
    }

    *:active {
        border-width: 0px;
    }

    /* a {
        color: black;
    }

    * {
        background-color: #e8ebe4;
    }

    #bodyDiv {
        background-color: #e8ebe4;
        text-align: center;
        display: flex;
        flex-direction: column;
        flex-basis: auto;
        align-items: center;
        gap: 1rem;
    }

    .title {
        color: #2D2D2A;
        font-size: 2rem;
        font-weight: bold;
    }

    h1 {
        color: #2D2D2A;
    }

    h2 {
        color: #353831;
    }

    .box {
        border: 1px solid #999AC6;
        display: flex;
        text-align: left;
        padding: 15px;
        background-color: #fff;
        border-radius: 0.5rem;
        max-width: 20rem;
        box-shadow: 12px 12px 2px 1px #a6a9a2;
        display: flex;
        gap: 0.9rem;
    }

    .form {
        flex-direction: column;
    }

    .box * {
        background-color: #fff;
    }

    .form input {
        height: 3rem;
        border-radius: 10px;
        padding: 0px 10px 0px;
        font-size: large;
        border: grey 1px solid;
    }

    .form button {
        height: 3rem;
        border-radius: 10px;
        background-color: #e8ebe4;
        color: #2D2D2A;
        font-weight: bold;
        font-size: 1.25rem;
        cursor: pointer;
    }

    .homeScaffold {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .friendsSection {
        min-width: 40vh;
        height: 100vh;
        background-color: red;
        border-right: #2D2D2A 1px solid;
        display: flex;
        flex-direction: column;
    }

    .friendsSectionItem {
        display: flex;
        flex-direction: row;
        flex-basis: auto;
        align-items: center;
        gap: 1rem;
    }

    .friendsSectionItem img {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        margin: 0.5rem;
    }

    .chatSection {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .chatHeader {
        align-items: center;
        display: flex;
        flex-direction: row;
        border-bottom: #2D2D2A 1px solid;
    }

    .chatHeader img {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        margin: 0.5rem;
    }

    .chatContent {
        flex-grow: 1;
        overflow: auto;
        height: 80vh;
        display: flex;
        flex-direction: column;
        gap: 3vh;

    }

    .MessageInputComponent {
        background-color: purple;
        display: flex;
        flex-direction: row;
        height: 80px;
    }

    .MessageInputComponent input {
        flex-grow: 1;
        border-radius: 10px;
        height: 45px;
        padding-left: 15px;
    }

    .message {
        display: flex;
    }

    .message img {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        margin: 0.5rem;
    }


    .chatHeaderAndContent {
        flex-grow: 1;
    }

    .senderNameTimeSentSection {
        display: flex;
        gap: 0.5rem;
    }

    .profileImgMed {

        object-fit: cover;
        width: 12rem;
        height: 12rem;
        border-radius: 50%;
        margin: 0.5rem;

    }

    .imgUploadSection {
        display: flex;
        justify-content: center;
    }

    .imgUploadSection input {
        display: none;
    } */